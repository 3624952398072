import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getRoomsForFloor } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import { PaginationQuery } from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { BaseModel } from "../../models";
import { Filter } from "../device-filter/DeviceFilter";
import { RoomModel } from "../../models/locations.model";

type props = {
  floorId: string;
  onRoomChosen?: (room: RoomModel) => void;
};

const FloorRoomsDisplay = ({ floorId, onRoomChosen }: props) => {
  const navigate = useNavigate();

  const getFloorRoomsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
      getRoomsForFloor(paginationQuery, floorId, filter.searchTerm),
    [floorId]
  );

  const navigateToFloorRoom = (room: BaseModel) => {
    navigate(`rooms/${room.id}`);
  };

  const handleRoomChosen = onRoomChosen ? onRoomChosen : navigateToFloorRoom;

  return (
    <ItemDisplay
      itemName="rooms"
      getItems={getFloorRoomsDisplaysPaginationQuery}
      labelProperty={"name"}
      handleItemChosen={handleRoomChosen}
    />
  );
};

export default FloorRoomsDisplay;
