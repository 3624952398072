import { Alert, CircularProgress, Grid, Skeleton } from "@mui/material";
import { PagedResponse, PaginationQuery } from "../../utilities/api";
import SearchBar from "../search-bar/SearchBar";
import {
  ItemWithId,
  Item,
  useItemPaginationSearch,
} from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import PaginatedList from "../item-lists/PaginatedList";
import { Filter } from "../device-filter/DeviceFilter";
import { ItemComponentProps } from "../item-lists/ItemList";
import { useEffect } from "react";

const DEFAULT_MAX_ITEM_LIST_LENGTH = 9;

type props = {
  itemName: string;
  getItems: (
    paginationQuery: PaginationQuery,
    filter: Filter
  ) => Promise<PagedResponse<ItemWithId> | null>;
  labelProperty: string;
  handleItemChosen: (item: any) => void;
  maxItemsPerPage?: number;
  getLabel?: (item: Item) => string;
  width?: number | string;
  height?: number | string;
  disabledItemId?: string | null;
  searchdisabled?: boolean;
  loadingComponent?: "circularProgress" | "skeleton" | "none";
  disableAutoSelectOnOneElement?: boolean;
  itemComponent?: ((props: ItemComponentProps) => JSX.Element) | null;
  isDisabled?: (item: Item) => boolean;
  disableDivideLastItem?: boolean;
  useTable?: boolean;
  reloadkey?: string;
};

const ItemDisplay = ({
  itemName,
  getItems,
  labelProperty,
  handleItemChosen,
  getLabel,
  maxItemsPerPage = DEFAULT_MAX_ITEM_LIST_LENGTH,
  width = "inherit",
  disabledItemId = null,
  searchdisabled = false,
  loadingComponent = "circularProgress",
  height = "inherit",
  disableAutoSelectOnOneElement = false,
  itemComponent = null,
  useTable = false,
  disableDivideLastItem = false,
  isDisabled = (_: Item) => false,
  reloadkey = "",
}: props) => {
  const {
    selectFirstItem,
    setSelected,
    onKeyPress,
    setInputValue,
    setSelectedFromList,
    populateWithData,
    items,
    constructLabel,
    selected,
    handlePaginatedListSelection,
    showPaginationBar,
    totalNumberOfPages,
    currentPage,
    handlePaginationChange,
    fetchstate,
  } = useItemPaginationSearch({
    getItems,
    labelProperty,
    handleItemChosen,
    getLabel,
    maxItemsPerPage,
    disableAutoSelectOnOneElement,
  });

  useEffect(() => {
    populateWithData();
  }, [reloadkey, populateWithData]);

  return (
    <Grid
      container
      justifyContent={"center"}
      maxWidth={"100%"}
      onFocus={selectFirstItem}
      onBlur={() => {
        setSelected(null);
      }}
      height={height}
      minHeight={height}
      onKeyPress={onKeyPress}
      width={width}
    >
      {(fetchstate === "default" || fetchstate === "loading") && (
        <>
          {loadingComponent === "circularProgress" && (
            <CircularProgress sx={{ alignSelf: "center", margin: 10 }} />
          )}

          {loadingComponent === "skeleton" && (
            <Skeleton width={width} height={height} variant="rectangular" />
          )}
        </>
      )}
      {fetchstate === "error" && (
        <Grid item>
          <Alert severity="error">Unable to fetch {itemName} from api.</Alert>
        </Grid>
      )}
      {fetchstate === "fetched" && (
        <>
          <Grid
            container
            maxWidth={"100%"}
            flexDirection={"column"}
            minHeight={height}
            marginBottom={1}
          >
            {!searchdisabled && (
              <Grid item>
                <SearchBar
                  onInputChange={(inputValue) => {
                    setInputValue(inputValue);
                  }}
                  onFocus={() => setSelectedFromList(false)}
                  onInputValueEmpty={populateWithData}
                  label={`Search ${itemName}`}
                />
              </Grid>
            )}

            <PaginatedList
              constructLabel={constructLabel}
              useTable={useTable}
              currentPage={currentPage}
              disabledItemId={disabledItemId}
              handleItemChosen={handleItemChosen}
              handlePaginatedListSelection={handlePaginatedListSelection}
              handlePaginationChange={handlePaginationChange}
              items={items}
              selected={selected}
              showPaginationBar={showPaginationBar}
              totalNumberOfPages={totalNumberOfPages}
              itemComponent={itemComponent}
              isDisabled={isDisabled}
              disableDivideLastItem={disableDivideLastItem}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ItemDisplay;
