import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Checkbox,
  Box,
} from "@mui/material";
import { RoomModel } from "../../models/locations.model";
import {  useMemo, useState } from "react";
import {
  getPrevInstallFromStorage,
  getPrevInstallRoomFromStorage,
} from "../../utilities/storage/prevDeviceInstallStorage";
import { useTenant } from "../../hooks/tenant";
import RoomsOrFloorRoomsDisplay from "../rooms-display/RoomsOrFloorRoomsDisplay";

type props = {
  room: RoomModel | null;
  handleRoomChosen: (room: RoomModel | null) => void;
  onConfirm: (room: RoomModel) => void;
};

const InstallLocationSelector = ({
  room: roomIn,
  handleRoomChosen,
  onConfirm,
}: props) => {
  const [room, setRoom] = useState<RoomModel | null>(roomIn);
  const [showList, setShowList] = useState(room === null);
  const prevInstall = getPrevInstallFromStorage();
  const prevInstallFloor = getPrevInstallRoomFromStorage()?.floor;

  const tenant = useTenant();

  const [isFloorCheck, setIsFloorCheck] = useState(true);

  const shouldShowPrevFloor = useMemo(
    () =>
      tenant?.identifier === prevInstall?.tenant.identifier &&
      !!prevInstallFloor,
    [tenant, prevInstall, prevInstallFloor]
  );

  const floorId = useMemo(() => {
    if (isFloorCheck && prevInstallFloor?.id && shouldShowPrevFloor) {
      return prevInstallFloor.id; // Create a new array reference here
    }
    return null; // Create a new array reference here when there's no floor ID
  }, [prevInstallFloor, isFloorCheck, shouldShowPrevFloor]);

  const onButtonClicked = () => {
    setShowList((showList) => !showList);
    handleRoomChosen(null);
  };

  const roomChosen = (room: RoomModel) => {
    setShowList(false);
    setRoom(room);
    handleRoomChosen(room);
    onConfirm(room);
  };

  const handleCheckboxChange = () => {
    setIsFloorCheck((c) => !c);
  };

  return (
    <>
      {showList ? (
        <>
          <RoomsOrFloorRoomsDisplay
            handleRoomChosen={roomChosen}
            floorId={floorId}
          />

          {shouldShowPrevFloor && (
            <div onClick={handleCheckboxChange}>
              <Checkbox
                checked={isFloorCheck}
              />
             <Box component={"span"}> Only show rooms in the same floor as your last install, floor <b>{prevInstallFloor!.name}</b>.</Box>
            </div>
          )}
        </>
      ) : (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name of room </TableCell>
                <TableCell align="center">
                  <b>{room?.name}</b>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Type of room</TableCell>
                <TableCell align="center">{room?.type.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Floor</TableCell>
                <TableCell align="center">{room?.floor.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid container justifyContent="center" marginTop={2}>
            <Button onClick={onButtonClicked} variant="outlined">
              Choose different room
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default InstallLocationSelector;
